<div
  class="checkbox__input"
  [class.is-active]="value"
  (click)="toggle()"
>
  <svg-icon
    *ngIf="value"
    key="check"
    size="xs"
  ></svg-icon>
</div>

<div class="checkbox__label">
  <ng-content></ng-content>
</div>
