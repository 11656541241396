import { Injectable } from '@angular/core';
import {Coin, SendIbcTokensRequest, SendTokensRequest, Wallet} from 'decentr-js';
import {combineLatest, defer, Observable, of, tap} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MessageBus } from '@shared/message-bus';
import { assertMessageResponseSuccess, CharonAPIMessageBusMap } from '@scripts/background/charon-api/message-bus-map';
import { MessageCode } from '@scripts/messages';
import { AuthService } from '../../auth';
import { DecentrService } from '../decentr';

enum IbcChannel {
  DecentrSentinel = 'channel-4',
}

enum IbcPort {
  Transfer = 'transfer',
}

const IBC_TIMEOUT: number = 60;

export const IbcRequestParams = {
  channel: IbcChannel,
  port: IbcPort,
  timeout: IBC_TIMEOUT,
};

@Injectable()
export class BankService {
  constructor(
    private authService: AuthService,
    private decentrService: DecentrService,
  ) {
  }

  public getDECBalance(): Observable<string> {
    return combineLatest([
      this.decentrService.decentrClient,
      this.authService.getActiveUserAddress(),
    ]).pipe(
      switchMap(([client, walletAddress]) => client.bank.getDenomBalance(walletAddress)),
      map((coin: Coin) => coin?.amount || '0'),
      catchError(() => of('0')),
    );
  }

  public getBalance(walletAddress: Wallet['address']) {
    return this.decentrService.decentrClient.pipe(
      switchMap(client => client.bank.getBalance(walletAddress)),
    );
  }

  public getTransferFee(request: Omit<SendTokensRequest, 'fromAddress'>): Observable<number> {
    return this.decentrService.decentrClient.pipe(
      switchMap((client) => client.bank.sendTokens({
        fromAddress: this.authService.getActiveUserInstant().wallet.address,
        toAddress: request.toAddress,
        amount: request.amount,
      }).simulate()),
    );
  }

  public getTransferIbcFee(request: Pick<SendIbcTokensRequest, 'receiver' | 'token'>): Observable<number> {
    return this.decentrService.decentrClient.pipe(
      switchMap((client) => client.bank.sendIbcTokens({
        receiver: request.receiver,
        sender: this.authService.getActiveUserInstant().wallet.address,
        sourcePort: IbcRequestParams.port.Transfer,
        sourceChannel: IbcRequestParams.channel.DecentrSentinel,
        timeoutSec: IbcRequestParams.timeout,
        token: request.token,
      }).simulate()),
    );
  }

  public transferCoins(
    request: Omit<SendTokensRequest, 'fromAddress'>,
    memo?: string,
  ) {
    const wallet = this.authService.getActiveUserInstant().wallet;
    return this.decentrService.decentrClient.pipe(
      switchMap(decentrClient => decentrClient.bank.sendTokens(
        { ...request, fromAddress: wallet.address },
      ).signAndBroadcast(memo)),
    ).pipe(
      map(txResponse => ({
        success: txResponse.code === 0,
        txHash: txResponse.transactionHash
      })),
      map(assertMessageResponseSuccess),
    );

    // return defer(() => new MessageBus<CharonAPIMessageBusMap>().sendMessage(
    //   MessageCode.CoinTransfer,
    //   {
    //     request: {
    //       ...request,
    //       fromAddress: wallet.address,
    //     },
    //     memo,
    //   },
    // )).pipe(
    //   map(assertMessageResponseSuccess),
    // );
  }

  public sendIbcTokens(
    request: SendIbcTokensRequest,
    memo?: string,
  ): Observable<void | string> {
    const wallet = this.authService.getActiveUserInstant().wallet;

    return defer(() => new MessageBus().sendMessage(
      MessageCode.SendIbcTokens,
      {
        request: {
          ...request,
          fromAddress: wallet.address,
        },
        memo,
      },
    )).pipe(
      map(assertMessageResponseSuccess),
    );
  }

  public sentinelSendIbcTokens(
    request: SendIbcTokensRequest,
    walletAddress: Wallet['address'],
    memo?: string,
  ): Observable<void | string> {
    console.log({
    ...request, fromAddress: walletAddress,
    })

    return defer(() => new MessageBus().sendMessage(
      MessageCode.SentinelSendIbcTokens,
      {
        request: {
          ...request,
          fromAddress: walletAddress,
        },
        memo,
      },
    )).pipe(
      map(assertMessageResponseSuccess),
    );
  }
}
