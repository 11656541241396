<div class="authorized-layout__header-container">
  <app-authorized-layout-header
    class="authorized-layout__header"
  ></app-authorized-layout-header>

  <app-authorized-layout-navigation
    class="authorized-layout__navigation"
    *ngIf="hasNavigation"
  ></app-authorized-layout-navigation>
</div>

<div
  #contentContainer
  class="authorized-layout__content-container"
>
  <router-outlet></router-outlet>
</div>

<ng-container *appSlotContainer="footerSlotName">
  <app-layout-footer *appBrowserTabView></app-layout-footer>
</ng-container>
