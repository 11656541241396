import { NgModule } from '@angular/core';

import { AvatarComponent } from './avatar.component';

@NgModule({
  declarations: [
    AvatarComponent,
  ],
  exports: [
    AvatarComponent,
  ],
})
export class AvatarModule {
}
