export enum AppRoute {
  Hub = 'hub',
  Login = 'login',
  Maintenance = 'maintenance',
  Offline = 'offline',
  Portal = 'portal',
  SignUp = 'sign-up',
  User = 'user',
  Update = 'update',
  Welcome = 'welcome',
}
