<div class="authorized-layout-header__section">
  <a
    class="authorized-layout-header__logo"
    *appSlotContainer="logoSlotName"
    [routerLink]="['./']"
    [target]="isOpenedInPopup ? 'blank' : ''"
  >
    <div class="authorized-layout-header__logo-icon"></div>
  </a>

  <div class="authorized-layout-header__meta-container">
    <ng-container *appSlotContainer="metaSlotName; rootElement: elementRef.nativeElement"></ng-container>
  </div>
</div>

<div class="authorized-layout-header__section">
  <div class="authorized-layout-header__actions-container">
    <ng-template [appSlotContainer]="actionsSlotName"></ng-template>
  </div>

  <app-menu></app-menu>
</div>
