<div
  class="input__label"
  appTypeface="paragraph"
  [appTypeface]="isOpenedInPopup ? 'caption' : 'paragraph'"
>
  <ng-content select="[label]"></ng-content>

  <ng-content select="[label-right]"></ng-content>
</div>

<div class="input__container">
  <ng-template [ngIf]="useTextArea" [ngIfThen]="textArea" [ngIfElse]="input"></ng-template>

  <svg-icon
    class="input__eye"
    *ngIf="type === 'password' && eye"
    [key]="valueSecured ? 'eye-crossed' : 'eye'"
    (click)="onEyeClick()"
  ></svg-icon>
</div>

<div
  #footer
  class="input__footer"
  [class.mod-visible]="footer.childNodes.length > 0"
  [appTypeface]="isOpenedInPopup ? 'caption' : 'paragraph'"
>
  <ng-content select="[footer]"></ng-content>
</div>

<ng-template #textArea>
  <textarea
    #textareaElement
    class="input mod-textarea"
    [cdkTextareaAutosize]="resize"
    [cdkAutosizeMinRows]="rows"
    [rows]="rows"
    [maxlength]="maxlength"
    [placeholder]="placeholder || ''"
    [disabled]="isDisabled"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [attr.spellcheck]="spellcheck"
    [attr.type]="type ==='password' && valueSecured ? 'password' : 'text'"
    [appAutoFocus]="autofocus"
    trim="blur"
  ></textarea>
</ng-template>

<ng-template #input>
  <input
    #inputElement
    class="input"
    [placeholder]="placeholder || ''"
    [maxlength]="maxlength"
    [type]="type ==='password' && valueSecured ? 'password' : 'text'"
    [disabled]="isDisabled"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [attr.spellcheck]="spellcheck"
    [appNumeric]="numeric"
    [appAutoFocus]="autofocus"
    trim="blur"
  >
</ng-template>
