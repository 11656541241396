<div class="public-layout__logo-container">
  <div
    class="public-layout__logo"
    *ngIf="includeLogo$ | async"
  ></div>
</div>

<div>
  <router-outlet></router-outlet>
</div>

<app-layout-footer
  *appBrowserTabView
  class="public-layout__footer"
></app-layout-footer>
