import {
  Coin,
  SentinelNodeStatus as NodeStatus,
} from 'decentr-js';

import { Denom } from '@shared/pipes/price/price.definitions';

export const DEFAULT_DENOM = Denom.IBC_DECENTR;

export interface SentinelNodeStatus extends NodeStatus {
  countryCode: string;
  gigabyte_prices: string | Coin;
  remoteUrl: string;
  statusAt: Date;
}
